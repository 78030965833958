import { FORM_SUBMIT_STATE } from 'awds'
import { SUPPORTED_FIELD_DATA_TYPES } from '../data-layer/forms/forms-config'
import mapperFormField from '../data-layer/forms/forms-mapper'

export const defaultFormProps = {
  /**
     * Marketo form id
     */
  formId: { type: [Number, String], required: true },
  /**
     * Route path to redirect to when form is successfully submitted (optional)
     */
  formSuccessRedirectPath: { type: String, default: '' },
  resetFormState: { type: Boolean, default: true }
}

export const useForms = (emit: Function) => {
  const { localeGlobalContentData } = useGlobalData()
  const { initRecaptcha, recaptcha } = useRecaptcha()
  const router = useRouter()
  const gtm = useGtm()
  const { locale, messages } = useI18n()
  const marketoFormFields = ref([])
  const utmListCookie = useCookie('utm_list')
  const utmStampCookie = useCookie('utm_stamp')

  const formId = ref(null)
  const formSuccessRedirectPath = ref()
  const resetFormState = ref(false)

  const data = ref({})
  const formFetchStatus = ref('')
  let refresh = () => { }

  /**
     * Form submit state
     */
  const formSubmitState = ref(FORM_SUBMIT_STATE.start)
  /**
     * First interacted field for tracking
     */
  const firstInteractedField = ref('')

  watch(
    formId,
    async (newFormId) => {
      try {
        const { data: useFetchData, status, refresh: useFetchRefresh } = await useFetch('/api/v3/marketo/form-fields', {
          query: {
            formId
          }
        })
        data.value = useFetchData.value
        marketoFormFields.value = useFetchData.value?.result || []
        formFetchStatus.value = status.value
        refresh = useFetchRefresh
      } catch (e) {
        throw new Error(e.message)
      }
    },
    { immediate: true }
  )

  onMounted(async () => {
    await nextTick()
    refresh()
  })

  // watchEffect(() => {
  //   if (data.value?.success && data.value.result) {
  //     console.log('>>> setting marketo fields here to:', data.value.result)
  //     marketoFormFields.value = data.value.result
  //   }
  // })

  const formLabels = computed(() => {
    return {
      ...localeGlobalContentData.value?.filterLabels,
      ...localeGlobalContentData.value?.formLabels
    }
  })
  const formFieldsHidden = computed(() => {
    return marketoFormFields.value?.filter(field => field.dataType === 'hidden') || []
  })
  /**
   * Get form fields
   * creates an array with all fields information to be used in the form component
   * @returns {Array}
   */
  const formFields = computed(() => {
    if (!marketoFormFields.value) {
      return []
    }

    // map only the translations that correspond to form fields
    // object key should match the `translationKey` field config defined in `./config.js` file
    const translations = {
      countries: messages.value[locale.value]?.countries,
      companySegments: messages.value[locale.value]?.companySegments,
      salesChannelSingle: messages.value[locale.value]?.salesChannelSingle,
      productNeeds: messages.value[locale.value]?.productNeeds,
      platformActiveUsers: messages.value[locale.value]?.platformActiveUsers,
      industry: messages.value[locale.value]?.industry,
      partnerTypes: messages.value[locale.value]?.partnerTypes,
      partnerChannel: messages.value[locale.value]?.partnerChannel,
      ecommercePlatform: messages.value[locale.value]?.ecommercePlatform,
      preferredLanguage: messages.value[locale.value]?.preferredLanguage,
      department: messages.value[locale.value]?.department
    }

    return marketoFormFields.value
      .filter(field => SUPPORTED_FIELD_DATA_TYPES.includes(field.dataType))
      .map(field => mapperFormField(field, formLabels.value, translations, locale.value)) || []
  })

  const isFormSubmitStateSuccess = computed(() => {
    return formSubmitState.value === formSubmitState.success
  })

  /**
     * Get form hidden parameters,
     * creates an object with all hidden fields information to be used in the submit method
     * @returns {Object}
     */
  function getFormHiddenParams() {
    if (import.meta.server || !window) {
      return
    }
    const w = window as any
    // ---- 1 - googleID (google analytics client id) ------ //
    const googleClientId = w.ga && w.ga.getAll()[0].get('clientId')
    // ---- 2 - Google Click ID (GCLID) if is not in the url get it from sessionStorage ------ //
    const searchParams = new URLSearchParams(document.location.href)
    const gclidParam = searchParams.get('gclid')
    const gclid = gclidParam || w.sessionStorage.getItem('gclid') || ''

    // Hidden values
    const hiddenValues = {
      gaid: googleClientId,
      Google_Analytics_Client_ID__c: googleClientId,
      gCLID: gclid,
      LastUTMArray__c: utmListCookie.value || '',
      InitialLeadSourceDetail: utmStampCookie.value?.initial_utm_source || '',
      Initial_Lead_Source_Detail__c: utmStampCookie.value?.initial_utm_source || '',
      InitialLeadCampaign: utmStampCookie.value?.initial_utm_campaign || '',
      Initial_Campaign__c: utmStampCookie.value?.initial_utm_campaign || '',
      InitialLeadSource: utmStampCookie.value?.initial_utm_medium || '',
      InitialLeadSource__c: utmStampCookie.value?.initial_utm_medium || '',
      InitialPartnerSource__c: utmStampCookie.value?.initial_utm_partner || '',
      CurrentLeadSourceDetail: utmStampCookie.value?.current_utm_source || '',
      Current_Lead_Source_Detail__c: utmStampCookie.value?.current_utm_source || '',
      CurrentLeadCampaign: utmStampCookie.value?.current_utm_campaign || '',
      Current_Campaign__c: utmStampCookie.value?.current_utm_campaign || '',
      CurrentLeadSource: utmStampCookie.value?.current_utm_medium || '',
      CurrentLeadSource__c: utmStampCookie.value?.current_utm_medium || '',
      PartnerSource: utmStampCookie.value?.current_utm_partner || '',
      PartnerSource__c: utmStampCookie.value?.current_utm_partner || '',
      lastFormReferrerURL: `${w.location.origin}${w.location.pathname}`
    }

    // Values from form hidden fields
    const formFieldsHiddenValues = [...formFieldsHidden.value.values()]

    // Compute hidden params
    return formFieldsHiddenValues.reduce((acc, curr) => {
      const item = { [curr.id]: Object.keys(hiddenValues).includes(curr.id) ? hiddenValues[curr.id] : curr.autoFill.value }
      return { ...acc, ...item }
    }, {})
  }
  /**
   * Submit form,
   * submits form data to the marketo endpoint and updates form submit state
   *
   * Documnetation: https://developers.marketo.com/rest-api/lead-database/leads/#submit_form
   */
  async function formSubmit({ formData, formFields }: { formData: any, formFields: any }) {
    if (import.meta.server || !window) {
      return
    }
    const w = window as any

    // Format specific form fields to match Marketo expected data structure
    const formattedFormData = Object.keys(formData).reduce((acc, fieldName) => {
      const formField = formFields.find(f => f.name === fieldName)
      if (formField && formField.multiple && formData[fieldName]) {
        // select multiple fields should be sent as string separated by `; `
        acc[fieldName] = formData[fieldName].join('; ')
      } else {
        acc[fieldName] = formData[fieldName]
      }
      return acc
    }, {})

    // complete form payload to be sent
    const formPayload = {
      formId: formId.value,
      formData: { ...formattedFormData, ...getFormHiddenParams() },
      // cookie: this.$cookie.get('_mkto_trk'),
      visitorData: {
        pageURL: `${w.location.origin}${w.location.pathname}`,
        queryString: w.location.search,
        userAgentString: w.navigator.userAgent
      }
    }

    // on local response with success state
    if (useRuntimeConfig().public.isDev) {
      // eslint-disable-next-line no-console
      console.info('[From] [Payload]', formPayload)
      formSubmitState.value = FORM_SUBMIT_STATE.success
      // emit success event
      emit('success', { formData: formPayload.formData })
      return
    }

    let recaptchaToken
    try {
      recaptchaToken = await recaptcha.value!.execute('submit')
      if (!recaptchaToken) {
        console.error('No recaptcha token')
        return
      }
    } catch { }
    // set state loading
    formSubmitState.value = FORM_SUBMIT_STATE.loading

    try {
      const data = await $fetch('/api/v3/marketo/submit-form', { method: 'POST', body: { formPayload, recaptchaToken } })
      if (data?.result?.find(i => ['created', 'updated'].includes(i.status))) {
        // set state success when the result status is created
        formSubmitState.value = FORM_SUBMIT_STATE.success
        // emit success event
        emit('success', { formData: formPayload.formData })
        // form event tracking
        trackForm(FORM_SUBMIT_STATE.success, formPayload)
        // Redirect in case a path is provided
        if (formSuccessRedirectPath.value) {
          router.replace({ path: `${formSuccessRedirectPath.value}` })
        }
      } else {
        // set state fail
        formSubmitState.value = FORM_SUBMIT_STATE.fail
        // form event tracking
        trackForm(FORM_SUBMIT_STATE.fail, formPayload)
      }
    } catch (error) {
      // set state fail
      formSubmitState.value = FORM_SUBMIT_STATE.fail
      // form event tracking
      trackForm(FORM_SUBMIT_STATE.fail, formPayload)
    } finally {
      // reset state
      if (resetFormState) {
        setTimeout(() => {
          formSubmitState.value = FORM_SUBMIT_STATE.start
        }, 5000)
      }
    }
  }

  function trackForm(state, data = {}) {
    const { formId, formData = {} } = data
    // Track form success
    if (!gtm) {
      return
    }

    gtm.push({
      event: 'form',
      form: {
        state,
        id: formId,
        data: { ...formData, firstInteractedField: firstInteractedField.value }
      }
    })
  }
  function trackFormInteraction(event: any) {
    if (!gtm) {
      return
    }

    gtm.push({
      event: 'form_interacted',
      form: {
        id: formId.value,
        data: { ...event, ...getFormHiddenParams() }
      }
    })
    firstInteractedField.value = event.fieldName
  }

  onMounted(async () => {
    try {
      await nextTick()
      await initRecaptcha()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  })

  // onBeforeUnmount(() => {
  //   if (recaptcha.value) {
  //     console.log(recaptcha.value)
  //     // recaptcha.value.destroy()
  //   }
  // })

  return {
    FORM_SUBMIT_STATE,
    formLabels,
    formId,
    marketoFormFields,
    formFields,
    formSubmitState,
    firstInteractedField,
    defaultFormProps,
    isFormSubmitStateSuccess,
    formFieldsHidden,
    formFetchStatus,
    // fetchInitialData,
    formSubmit,
    trackForm,
    trackFormInteraction

  }
}
