import { formValidationRules } from 'awds'

/**
 * Apply custom order for country select options
 * Depending on the locale it will show the configured `orderPerLocale` options at the begining of the list
 *
 * @param {Object[]} data - Marketo form field select options list
 * @param {String} locale - i18n locale
 * @returns {Object[]} - ordered select options list
 */
const countryCustomOrder = (data, locale) => {
  const orderPerLocale = {
    en: ['US', 'DE', 'NL', 'GB', 'FR'],
    pt_BR: ['BR'],
    zh_CN: ['CN', 'HK'],
    cs_CZ: ['CZ'],
    da_DK: ['DK'],
    de_DE: ['DE', 'AT', 'CH'],
    es_ES: ['ES'],
    fr_FR: ['FR'],
    it_IT: ['IT'],
    ja_JP: ['JP'],
    es_MX: ['MX'],
    nl_NL: ['NL', 'BE'],
    no_NO: ['NO'],
    pt_PT: ['PT'],
    sv_SE: ['SE'],
    pl_PL: ['PL'],
    en_GB: ['GB', 'IE', 'IM'],
    en_AU: ['AU']
  }

  if (!data || !Array.isArray(data)) {
    return data
  }

  // order alphabetically a-z
  // Warning! array mutation
  data.sort((a, b) => a.text.localeCompare(b.text))

  if (!orderPerLocale[locale]) {
    return data
  }

  const reversedOrder = orderPerLocale[locale].reverse()
  // apply custom order
  // Warning! array mutation
  data.sort((a, b) => reversedOrder.indexOf(b.value) - reversedOrder.indexOf(a.value))

  return data
}

// we only support these field types from Marketo
export const SUPPORTED_FIELD_DATA_TYPES = [
  'checkbox',
  'checkboxes',
  'email',
  'select',
  'telephone',
  'text',
  'textarea',
  'textArea',
  'url',
  'currency',
  'number'
]

// basic mapping per field type
const FIELDS = {
  email: {
    type: 'email',
    label: 'formFieldEmail',
    errorMessage: 'formFieldEmailErrorMessage',
    validate: formValidationRules.emailField,
    required: false,
    infoMessage: '',
    maxLength: '70'
  },
  telephone: {
    type: 'tel',
    label: 'formFieldPhoneNumber',
    errorMessage: 'formFieldPhoneNumberErrorMessage',
    validate: formValidationRules.phoneNumberField,
    required: false,
    initialValue: '+',
    infoMessage: ''
  },
  text: {
    type: 'text',
    label: '',
    errorMessage: 'formFieldNameErrorMessage',
    validate: formValidationRules.nameField,
    required: false,
    infoMessage: ''
  },
  url: {
    type: 'url',
    label: 'formFieldWebsite',
    errorMessage: 'formFieldWebsiteErrorMessage',
    // Use a more generic validation rule to allow urls with subdomain and 2 top level domains
    // @TODO: review if we want to move this validation to Design System
    validate: (value) => {
      return (/^https:\/\/(?:[-a-z0-9]{2,35}\.)?(?:[-a-z0-9]{2,35})(?:\.[a-z]{2,6}){1,2}$/).test(value)
    },
    required: false,
    initialValue: 'https://',
    infoMessage: '',
    maxLength: '70'
  },
  checkbox: {
    type: 'checkbox',
    label: '',
    errorMessage: '',
    validate: null,
    required: false,
    infoMessage: ''
  },
  checkboxes: {
    type: 'checkbox',
    label: '',
    errorMessage: '',
    validate: null,
    required: false,
    infoMessage: ''
  },
  select: {
    type: 'select',
    label: '',
    errorMessage: '',
    validate: null,
    required: false,
    infoMessage: '',
    placeholder: '',
    options: []
  },
  textarea: {
    type: 'textarea',
    label: 'formFieldMessage',
    errorMessage: '',
    validate: null,
    required: false,
    infoMessage: ''
  }
}

// mapping per field id
// Note: there are inconsistences in the field id's in the api response vs form submit payload
// e.g the id is 'FirstName' but we have to send it back as 'firstName'
export const SUPPORTED_FIELDS = {
  FirstName: {
    ...FIELDS.text,
    name: 'firstName',
    label: 'formFieldFirstName'
  },
  LastName: {
    ...FIELDS.text,
    name: 'lastName',
    label: 'formFieldLastName'
  },
  Company: {
    ...FIELDS.text,
    name: 'company',
    label: 'formFieldCompany',
    errorMessage: 'formFieldCompanyNameErrorMessage',
    validate: formValidationRules.companyNameField
  },
  Phone: {
    ...FIELDS.telephone,
    name: 'phone'
  },
  Website: {
    ...FIELDS.url,
    name: 'website',
    infoMessage: 'formFieldWebsiteInfoMessage'
  },
  Email: {
    ...FIELDS.email,
    name: 'email'
  },
  corporateEmailAddress: {
    ...FIELDS.email,
    name: 'corporateEmailAddress',
    label: 'formFieldCorporateEmail'
  },
  Consented_to_Contact__c: {
    ...FIELDS.checkboxes,
    label: 'formCheckboxConsentedToContact',
    errorMessage: 'formFieldRequiredErrorMessage'
  },
  PartnerNewsletter__c: {
    ...FIELDS.checkboxes,
    label: 'formCheckboxConsentedToContact',
    errorMessage: 'formFieldRequiredErrorMessage'
  },
  pressoptin: {
    ...FIELDS.checkboxes,
    label: 'formCheckboxConsentedToContact',
    errorMessage: 'formFieldRequiredErrorMessage'
  },
  CountryCode: {
    ...FIELDS.select,
    label: 'formFieldCountry',
    placeholder: 'formFieldCountryPlaceholder',
    infoMessage: 'formFieldCountryInfoMessage',
    options: 'countryOptions',
    translationKey: 'countries',
    order: countryCustomOrder
  },
  State: {
    ...FIELDS.select,
    name: 'state',
    label: 'formFieldStateProvinceTerritory',
    translationKey: 'states'
  },
  Industry: {
    ...FIELDS.select,
    name: 'industry',
    label: 'formFieldIndustry',
    translationKey: 'industry'
  },
  Form_text_2__c: {
    ...FIELDS.textarea,
    name: 'Form_text_2__c'
  },
  EstimatedATV__c: {
    type: 'number',
    name: 'EstimatedATV__c',
    label: 'formFieldEstimatedAtv'
  },
  NumberOfTransactionsAMonth__c: {
    type: 'number',
    name: 'NumberOfTransactionsAMonth__c',
    label: 'formFieldTransactionsAMonth',
    errorMessage: 'formFieldTransactionsAMonthErrorMessage',
    // Custom max validation to match Marketo max allowed number
    validate: (value) => {
      return value < 2147483647
    }
  },
  ECOMMERCE_PLATFORM__c: {
    ...FIELDS.select,
    label: 'formFieldEcommercePlatform',
    infoMessage: 'formSelectAll',
    // Options partically translated
    translationKey: 'ecommercePlatform'
  },
  POS_Sotfware__c: {
    ...FIELDS.select,
    label: 'formFieldPosSotfware',
    infoMessage: 'formSelectAll'
    // No translations needed for Options
  },
  PartnerChannelV2__c: {
    ...FIELDS.select,
    label: 'formFieldPartnerChannel',
    infoMessage: 'formSelectAll',
    translationKey: 'partnerChannel'
  },
  Company_Segment__c: {
    ...FIELDS.select,
    label: 'formFieldCompanySegment',
    infoMessage: 'formFieldCompanySegmentInfoMessage',
    translationKey: 'companySegments'
  },
  SalesChannelSingle__c: {
    ...FIELDS.select,
    label: 'formFieldSalesChannelSingle',
    translationKey: 'salesChannelSingle'
  },
  Product_Needs__c: {
    ...FIELDS.select,
    label: 'formFieldProductNeeds',
    translationKey: 'productNeeds',
    errorMessage: 'formFieldRequiredErrorMessage'
  },
  PartnerTypes__c: {
    ...FIELDS.select,
    label: 'formFieldPartnerTypes',
    translationKey: 'partnerTypes'
  },
  platformactiveusers: {
    ...FIELDS.select,
    label: 'formFieldPlatformActiveUsers',
    translationKey: 'platformActiveUsers'
  },
  referredby: {
    type: 'text',
    name: 'referredby',
    label: 'formFieldReferredBy'
  },
  Preferred_Language__c: {
    ...FIELDS.select,
    name: 'Preferred_Language__c',
    label: 'formFieldPreferredLanguage',
    translationKey: 'preferredLanguage'
  },
  Department__c: {
    ...FIELDS.select,
    name: 'Department__c',
    label: 'formFieldDepartment',
    translationKey: 'department'
  },
  Title: {
    type: 'text',
    name: 'title',
    label: 'formFieldJobTitle'
  },
  LinkedIn_Profile__c: {
    ...FIELDS.url,
    name: 'LinkedIn_Profile__c',
    label: 'formFieldLinkedInProfile',
    errorMessage: 'formFieldLinkedInProfileErrorMessage',
    maxLength: '150',
    // Custom url validation
    validate: (value) => {
      // eslint-disable-next-line no-useless-escape
      return (/^(https:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/).test(value)
    }
  },
  ServiceDescription__c: {
    ...FIELDS.checkboxes,
    name: 'ServiceDescription__c',
    label: 'formFieldServiceDescription'
  },
  SalesPayoutDay__c: {
    ...FIELDS.checkboxes,
    name: 'SalesPayoutDay__c',
    label: 'formFieldSalesPayoutDay'
  },
  afPSalesDayPayoutOptIn: {
    ...FIELDS.checkboxes,
    name: 'afPSalesDayPayoutOptIn',
    label: 'formFieldAfPSalesDayPayout'
  }
}
