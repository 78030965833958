import { SUPPORTED_FIELDS } from './forms-config'

/**
 * Field ID from Content Type `Global Data - Form Labels`
 * Represents the label for `Other` option present in most of the select fields
 */
const LABEL_SELECT_OPTION_OTHER = 'formSelectOptionOther'

/**
 * Gets field error message, concatenates error messages
 * if the field is required adds the generic error message
 * @param {Boolean} required
 * @param {String} requiredErrorMessage
 * @param {String} errorMessage
 * @returns {String}
 */
const geFieldErrorMessage = (required = false, requiredErrorMessage = '', errorMessage = '') => {
  const message = required ? `${requiredErrorMessage} ${errorMessage}` : errorMessage
  return message.trim()
}

/**
 * Gets select field options
 * formats options in order to be used by the select form component
 * @param {Object[]} values - Marketo select field options
 * @param {Object} fieldConfig - field configuration
 * @param {Object} translations - translations object (code translations from `/locales` dir)
 * @param {Object} labels - form labels from CMS
 * @param {String} locale - i18n locale
 * @returns {Object[]} - mapped select options
 */
const getSelectOptions = (values, fieldConfig, translations, labels, locale) => {
  if (!values) {
    return []
  }

  const { translationKey, order } = fieldConfig || {}
  const translationsForField = translations[translationKey] || {}
  const options = values
    .filter(i => !!i.value && translationsForField?.[i.value] !== '_HIDDEN_')
    .map((item, index) => ({
      id: index,
      text: (item.label === 'Other') ? labels[LABEL_SELECT_OPTION_OTHER] : (translationsForField[item.value] ?? item.label),
      value: item.value
    }))

  // Apply Custom order function
  if (order && order instanceof Function) {
    return order(options, locale)
  }

  return options
}

/**
 * Form field mapper,
 * formats field data in order to be used by form components
 * @param {Object} field - Marketo form field
 * @param {Object} labels - form labels from CMS
 * @param {Object} translations - translations object (code translations from `/locales` dir)
 * @param {String} locale - i18n locale
 * @returns {Object} mapped form field
 */
export default (field = {}, labels = {}, translations = {}, locale) => {
  const fieldConfig = SUPPORTED_FIELDS?.[field.id] || null

  const fieldMapping = {
    ...fieldConfig,
    name: fieldConfig?.name ?? field.id,
    type: fieldConfig?.type ?? field.dataType.toLowerCase(),
    label: labels?.[fieldConfig?.label] ?? field.label,
    required: field.required ?? false,
    errorMessage: geFieldErrorMessage(field.required, labels.formFieldRequiredErrorMessage, labels?.[fieldConfig?.errorMessage]),
    infoMessage: labels?.[fieldConfig?.infoMessage] ?? (field?.instructions || ''),
    visible: field?.visibilityRules?.rules ? field.visibilityRules.ruleType === 'alwaysShow' : true,
    ...(fieldConfig?.validate && {
      validate: fieldConfig.validate
    }),
    ...(field?.visibilityRules?.rules && {
      visibilityRules: field.visibilityRules.rules.map(rule => ({
        label: labels?.[fieldConfig?.label] ?? rule.altLabel,
        options: getSelectOptions(rule?.picklistFilterValues ?? field.fieldMetaData?.values, fieldConfig, translations, labels, locale),
        subjectFieldName: rule.subjectField,
        subjectFieldValues: rule.values
      }))
    })
  }

  // For select fields we need to add additional info
  if ((fieldConfig?.type || field.dataType) === 'select') {
    return {
      ...fieldMapping,
      labels: {
        results: labels.results,
        ariaLabelHint: labels.ariaLabelArrowNavigationHint,
        clearValueButton: labels.clearValueButton
      },
      multiple: !!field.fieldMetaData?.multiSelect,
      placeholder: labels?.[fieldConfig?.placeholder] ?? field.label,
      errorMessage: geFieldErrorMessage(field.required, labels.formFieldRequiredErrorMessage),
      options: getSelectOptions(field.fieldMetaData?.values, fieldConfig, translations, labels, locale)
    }
  }
  return fieldMapping
}
